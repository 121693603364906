(function(){
	//wrapping our js files wherever we write the in IIFE's helps to keep scope clean
	'use strict';
	//use strict gives us the ability to use Javascript 2015 style code.

	//Fix for the backward compatibility features removed and deprecated in the new angular version.
	angular.lowercase=function(text){
		if(typeof text != 'undefined'){
			return text.toLowerCase();
		}
	}

	angular.module('myApp',[
			'ngTagsInput',
			'ui.bootstrap',
			'ui.tinymce',
			'xeditable',
			'ngAnimate',
			'mgcrea.ngStrap',
			'angularUtils.directives.dirPagination',
			'ui.tree',
			'ngSanitize',
			'ngMaterial',
			"com.2fdevs.videogular",
			"com.2fdevs.videogular.plugins.controls",
			"com.2fdevs.videogular.plugins.overlayplay",
			"com.2fdevs.videogular.plugins.poster",
			// 'angular-chartist',
			'angular-growl',
			'froala',
			'textAngular',
			'chart.js'
		], function($interpolateProvider) {
		$interpolateProvider.startSymbol('[[');
		$interpolateProvider.endSymbol(']]');
	});
	angular.module('myApp').config(['growlProvider', function(growlProvider) {
		// growlProvider.globalDisableIcons(true);
		growlProvider.globalPosition('top-center');
		growlProvider.globalTimeToLive({success: 1000, error: 2000, warning: 3000, info: 4000});
	}]);
	angular.module('myApp').value('froalaConfig', {
		attribution: false,
		imageUploadURL: '/file_upload',
		videoUploadURL: '/file_upload',
		fileUploadURL: '/file_upload',
		toolbarButtons: ["bold", "italic", "underline", "|", "align", "formatOL", "formatUL", "insertVideo", "insertImage",]
	});
	//all I've done here is called Angular and bootstrapped 'myApp as an angular application.'
	// all of your global dependancies you'll want to put in the array passed here
	// i.e. angular.ui.bootstrap, ui.router etc.... whatever you need that is an
	// external dependancie that doesn't ship with the framework.
})();